import React, { Fragment } from 'react';
import './pageLoader.scss';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppContext } from '../../AppContext/App.context';

const PageLoader = (props: any) => {
	const [appData, dispatch]: any = useAppContext();
	return (
		<Fragment>
			<div
				className={
					localStorage.getItem('isInsightsOpened') == 'true'
						? 'insightsLoader pageLoader'
						: 'pageLoader'
				}
				style={
					appData.onSidebarToggle?.sidebarToggle
						? { zIndex: 'auto' }
						: { zIndex: 9999 }
				}
			>
				<div>
					{/* <img src={loadingGif}></img> */}
					<div style={{ height: '52px', width: '52px', zIndex: 9999 }}>
						<CircularProgress
							size={50}
							style={{ color: '#9149FF', zIndex: 9999 }}
						/>
					</div>
				</div>
			</div>
		</Fragment>
	);
};
export default PageLoader;
