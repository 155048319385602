export const AppUtilityFunctions = {
	getRedirectUrl() {
		let redirectUrl = window.location.origin + '/login';
		let urlArray: any = window.location.href.split('?')[1];
		if (urlArray) {
			const keyValuePairs = urlArray.split('&');

			let keyValueArray: any = [];

			// Iterate over the key-value pairs and split them by the '=' character to separate keys and values
			keyValuePairs.forEach((pair: any) => {
				const [key, value] = pair.split('=');
				keyValueArray.push({ key, value });
			});
			let paramsToAdd = '';
			for (let index = 0; index < keyValueArray.length; index++) {
				if (
					keyValueArray[index].key != 'session_state' &&
					keyValueArray[index].key != 'code'
				) {
					paramsToAdd =
						paramsToAdd +
						'?' +
						keyValueArray[index].key +
						'=' +
						keyValueArray[index].value;
				}
			}
			return redirectUrl + paramsToAdd;
		}
		return redirectUrl;
	},
	calculateTimeDifferenceInSeconds(date1: any, date2: any) {
		const timeDifferenceInMilliseconds = date1 - date2;
		const timeDifferenceInSeconds = Math.floor(
			timeDifferenceInMilliseconds / 1000
		);
		return timeDifferenceInSeconds;
	},

	getCurrentDateTimeWithAddedMinutes(minutesToAdd: any) {
		const currentDateTime = new Date(); // Get the current date and time
		const newDateTime = new Date(
			currentDateTime.getTime() + minutesToAdd * 60 * 1000
		); // Add minutes
		return newDateTime;
	},
	parseDateStringToDate(dateString: any) {
		// Parse the date string into a Date object
		return new Date(dateString);
	},
};
