const appEndpoints = {
	getLoginDetails(data: any) {
		return {
			url: 'governance/oauth',
			headers: data.headers,
			data: {},
		};
	},
	keyCloakAuth(data: any) {
		return {
			url: 'kcauth/verify',
			data: data.headers,
		};
	},
	regenrateKeyCloakToken(data: any) {
		return {
			url: 'kcauth/refreshToken',
			headers: data,
			data: {},
		};
	},
	validateAccessToken(data: any) {
		return {
			url: 'kcauth/validate',
			headers: data.headers,
			data: {},
		};
	},
	segment_filter_name(data: any) {
		return {
			url: 'segment/segment_filter_name',
			headers: data,
			data: {},
		};
	},
};
export default appEndpoints;
