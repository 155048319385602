import useAxiosInterceptor from '../utils/project-axios.instance';

interface IDataObject {
  url: string;
  data: object;
  headers?: object;
  API_BASE_URL?: string;
  accessTokenInHeaders?: boolean;
}

const useApiService = () => {
  let API_BASE_URL = process.env.REACT_APP_API_URL;
  const API_BASE_SNOWFLAKE_URL = "https://warehouse-docs-beta.diggrowth.com/";
  const API_BASE_URL_DOMAIN = process.env.REACT_APP_API_URL_DOMAIN;
  const axiosInstance = useAxiosInterceptor();
  const userDataJson: any = localStorage.getItem('userData');
  const userData: any = userDataJson ? JSON.parse(userDataJson) : null;
const access_token = userData ? userData['access-token'] : null;

  return {
    // ========= get ==============
    get(obj: IDataObject, accessTokenInHeaders?: boolean, snowflake?: boolean) {
      if (obj.API_BASE_URL) {
        API_BASE_URL = obj.API_BASE_URL;
      }
      if (accessTokenInHeaders)
        return axiosInstance.get(
          snowflake ? API_BASE_SNOWFLAKE_URL + obj.url : API_BASE_URL + obj.url,
          {
            params: {
              ...obj.data,
            },
            headers: {
              "access-token": access_token,
              ...obj.headers,
            },
          }
        );
      return axiosInstance.get(
        snowflake ? API_BASE_SNOWFLAKE_URL + obj.url : API_BASE_URL + obj.url,
        {
          params: {
            ...obj.data,
          },
          headers: { ...obj.headers },
        }
      );
    },
    // ========= post ==============
    post(
      obj: IDataObject,
      accessTokenInHeaders?: boolean,
      snowflake?: boolean
    ) {
      if (obj.API_BASE_URL) {
        API_BASE_URL = obj.API_BASE_URL;
      }
      if (accessTokenInHeaders)
        return axiosInstance.post(
          snowflake ? API_BASE_SNOWFLAKE_URL + obj.url : API_BASE_URL + obj.url,
          {
            ...obj.data,
          },
          {
            headers: {
              "access-token": access_token,
              ...obj.headers,
            },
          }
        );
      return axiosInstance.post(
        snowflake ? API_BASE_SNOWFLAKE_URL + obj.url : API_BASE_URL + obj.url,
        {
          ...obj.data,
        },
        { headers: { ...obj.headers } }
      );
    },
    // ========= put ==============
    put(obj: IDataObject, accessTokenInHeaders?: boolean, snowflake?: boolean) {
      if (obj.API_BASE_URL) {
        API_BASE_URL = obj.API_BASE_URL;
      }
      if (accessTokenInHeaders)
        return axiosInstance.put(
          snowflake ? API_BASE_SNOWFLAKE_URL + obj.url : API_BASE_URL + obj.url,
          {
            ...obj.data,
          },
          {
            headers: {
              "access-token": access_token,
              ...obj.headers,
            },
          }
        );
      return axiosInstance.put(
        snowflake ? API_BASE_SNOWFLAKE_URL + obj.url : API_BASE_URL + obj.url,
        {
          ...obj.data,
        },
        { headers: { ...obj.headers } }
      );
    },
    // ============ delete =================
    delete(
      obj: IDataObject,
      accessTokenInHeaders?: boolean,
      snowflake?: boolean
    ) {
      if (obj.API_BASE_URL) {
        API_BASE_URL = obj.API_BASE_URL;
      }
      if (accessTokenInHeaders)
        return axiosInstance.delete(
          snowflake ? API_BASE_SNOWFLAKE_URL + obj.url : API_BASE_URL + obj.url,
          {
            headers: { "access-token": access_token, ...obj.headers },
            data: {
              ...obj.data,
            },
          }
        );
      return axiosInstance.delete(
        snowflake ? API_BASE_SNOWFLAKE_URL + obj.url : API_BASE_URL + obj.url,
        {
          headers: { ...obj.headers },
          data: {
            ...obj.data,
          },
        }
      );
    },
    getDomain(obj: IDataObject) {
      return axiosInstance.get(API_BASE_URL_DOMAIN + obj.url, {
        params: {
          ...obj.data,
        },
      });
    },
    postDomain(obj: IDataObject) {
      return axiosInstance.post(API_BASE_URL_DOMAIN + obj.url, {
        ...obj.data,
      });
    },
    postFormData(obj: IDataObject, accessTokenInHeaders?: boolean) {
      if (accessTokenInHeaders) {
        return axiosInstance.post(API_BASE_URL + obj.url, obj.data, {
          headers: {
            "Content-Type": "multipart/form-data",
            "access-token": access_token,
            ...obj.headers,
          },
        });
      } else {
        return axiosInstance.post(API_BASE_URL + obj.url, obj.data, {
          headers: { "Content-Type": "multipart/form-data", ...obj.headers },
        });
      }
    },
    putFormData(obj: IDataObject, accessTokenInHeaders?: boolean) {
      if (accessTokenInHeaders) {
        return axiosInstance.put(API_BASE_URL + obj.url, obj.data, {
          headers: {
            "Content-Type": "multipart/form-data",
            "access-token": access_token,
            ...obj.headers,
          },
        });
      } else {
        return axiosInstance.put(API_BASE_URL + obj.url, obj.data, {
          headers: { "Content-Type": "multipart/form-data", ...obj.headers },
        });
      }
    },
  };
};

export default useApiService;
